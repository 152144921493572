/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .background-radial-gradient {
    background-color: hsl(218, 41%, 15%);
    background-image: radial-gradient(
        650px circle at 0% 0%,
        hsl(218, 41%, 35%) 15%,
        hsl(218, 41%, 30%) 35%,
        hsl(218, 41%, 20%) 75%,
        hsl(218, 41%, 19%) 80%,
        transparent 100%
      ),
      radial-gradient(
        1250px circle at 100% 100%,
        hsl(218, 41%, 45%) 15%,
        hsl(218, 41%, 30%) 35%,
        hsl(218, 41%, 20%) 75%,
        hsl(218, 41%, 19%) 80%,
        transparent 100%
      );
  }
}

/*@media (min-width: 992px) {*/
@media screen(md) {
  #cta-img-nml-50 {
    margin-left: 50px;
  }
}

@keyframes progress {
  from{
    width: 0;
  }to {
    width: 100%;
  }   
}

.background-radial-gradient {
  background-color: hsl(218, 41%, 15%);
  background-image: radial-gradient(
      650px circle at 0% 0%,
      hsl(218, 41%, 35%) 15%,
      hsl(218, 41%, 30%) 35%,
      hsl(218, 41%, 20%) 75%,
      hsl(218, 41%, 19%) 80%,
      transparent 100%
    ),
    radial-gradient(
      1250px circle at 100% 100%,
      hsl(218, 41%, 45%) 15%,
      hsl(218, 41%, 30%) 35%,
      hsl(218, 41%, 20%) 75%,
      hsl(218, 41%, 19%) 80%,
      transparent 100%
    );
}

.splide__pagination__page.active {
  background: #1097e6 !important;
}

[x-cloak] {
  display: none;
}

@media print {
  .no-printme {
    display: none;
  }
  .printme {
    display: block;
  }
  body {
    line-height: 1.2;
  }
}

@page {
  size: A4 portrait;
  counter-increment: page;
}

/* Datepicker */
.date-input {
  background-color: #fff;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  z-index: 2000;
  margin: 3px 0 0 0;
  border-top: 1px solid #eee;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.date-input.is-hidden {
  display: none;
}
.date-input .pika-title {
  padding: 0.5rem;
  width: 100%;
  text-align: center;
}
.date-input .pika-prev,
.date-input .pika-next {
  margin-top: 0;
  /* margin-top: 0.5rem; */
  padding: 0.2rem 0;
  cursor: pointer;
  color: #4299e1;
  text-transform: uppercase;
  font-size: 0.85rem;
}
.date-input .pika-prev:hover,
.date-input .pika-next:hover {
  text-decoration: underline;
}
.date-input .pika-prev {
  float: left;
}
.date-input .pika-next {
  float: right;
}
.date-input .pika-label {
  display: inline-block;
  font-size: 0;
}
.date-input .pika-select-month,
.date-input .pika-select-year {
  display: inline-block;
  border: 1px solid #ddd;
  color: #444;
  background-color: #fff;
  border-radius: 10px;
  font-size: 0.9rem;
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  appearance: none;
}
.date-input .pika-select-month:focus,
.date-input .pika-select-year:focus {
  border-color: #cbd5e0;
  outline: none;
}
.date-input .pika-select-month {
  margin-right: 0.25em;
}
.date-input table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 0.2rem;
}
.date-input table th {
  width: 2em;
  height: 2em;
  font-weight: normal;
  color: #718096;
  text-align: center;
}
.date-input table th abbr {
  text-decoration: none;
}
.date-input table td {
  padding: 2px;
}
.date-input table td button {
  /* border: 1px solid #e2e8f0; */
  width: 1.8em;
  height: 1.8em;
  text-align: center;
  color: #555;
  border-radius: 10px;
}
.date-input table td button:hover {
  background-color: #bee3f8;
}
.date-input table td.is-today button {
  background-color: #ebf8ff;
}
.date-input table td.is-selected button {
  background-color: #3182ce;
}
.date-input table td.is-selected button {
  color: white;
}
.date-input table td.is-selected button:hover {
  color: white;
}

.max-width-tb {
  max-width: 320px;
}

.upload-image-size {
  height: 90px;
  width: 230px;
}

.ImagenFondo {
  background-image: url("/public/img/cubo2.jpg");
}

.form-control-error {
  box-shadow: 0 0 0 1px rgb(239 68 68);
}

.form-control-error::placeholder {
  color: rgb(248 113 113);
}
::-ms-reveal {
  display: none;
}
.animate-spin-slower {
  animation: spin 6s linear infinite;
}
@keyframes spin {
  to {
    transform: rotate(1turn);
  }
}
.animate-spin-slower {
  animation: spin 4s linear infinite;
}
@keyframes spin-reverse {
  to {
    transform: rotate(-1turn);
  }
}
.floating { 
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floating {
  0% { transform: translate(0,  0px); }
  50%  { transform: translate(0, 15px); }
  100%   { transform: translate(0, -0px); }   
}

html::-webkit-scrollbar {
  -webkit-appearance: none;
}

html::-webkit-scrollbar:vertical {
  width:10px;
}

html::-webkit-scrollbar-button:increment,html::-webkit-scrollbar-button {
  display: none;
} 

html::-webkit-scrollbar:horizontal {
  height: 10px;
}

html::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

html::-webkit-scrollbar-track {
  border-radius: 10px;  
}

.css-2613gy-menu {
  background-color: #0055ff !important;
  color: #1a68ce !important;
  border-radius: 0.375rem !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  z-index: 1000000 !important;
}

.PhoneInputInput {
  background-color : transparent !important; 
}
/* Define una clase para eliminar el borde de enfoque */
.custom-focus-style:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

/* Ejemplo de clase para aplicar un estilo personalizado */
.custom-focus-style:focus {
  outline: none;
  border: 1px solid blue;  /* Cambia esto según tu necesidad */
  box-shadow: 0 0 5px blue; /* Ejemplo de sombra azul */
}


.select-async .select-option {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.chart-container {
  position: relative;
  width: 75%;
  height: 500px;
}

@media (max-width: 768px) {
  .chart-container {
    height: 300px;
    width: 100%;
  }
}

@media (max-width: 480px) {
  .chart-container {
    height: 200px;
    width: 100%;
  }
}