body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.responsiveGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  column-gap: 10px;
  row-gap: 10px;
}

input:invalid {
  appearance: none;
}


.formCrearBodega {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  column-gap: 20px;
  row-gap: 20px;
}

.file-upload {
  position: relative;
  display: inline-block;
  margin: 20px;

}

@media (max-width: 768px) {
  .hide-on-small {
    display: none;
  }
}

div::-webkit-scrollbar {
  width: 6px;
  height: 10px;
}

div::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

div::-webkit-scrollbar-thumb {
  background: rgb(233, 235, 236);
  border-radius: 10px;
}

div::-webkit-scrollbar-thumb:hover {
  background: #d5d6d7;
}

.custom-file-upload {
  display: inline-block;
  padding: 6px 20px 6px 20px;
  cursor: pointer;
  background-color: #1ce975;
  border-radius: 4px;
}

.custom-file-disabled {
  display: inline-block;
  padding: 6px 20px 6px 20px;
  cursor: not-allowed;
  background-color: #1c64f2;
  border-radius: 4px;
  font-size: 14px;
}

.NotFoundImage {
  width: 600px;
  height: 600px;
}

.custom-file-upload:hover {
  background-color: #08b736;
}

#fileInput {
  position: absolute;
  left: -9999px;

}

.custom-file-upload span {
  font-size: 14px;
  color: #ffffff;
}

.buttonsGrid {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.infoTransaccion {
  display: grid;
  grid-template-columns: repeat(4, minmax(0,1fr));
}

.infoTransferencia {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

@media (max-width: 1568px) {
  .buttonsGrid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

}

@media (max-width: 1800px) {
  .infoTransaccion{
    display: grid;
    align-content: center;
    grid-template-columns: repeat(2, minmax(0, 1fr));

  }

  .infoTransferencia{
    display: grid;
    align-content: center;
    grid-template-columns: repeat(2, minmax(0, 1fr));

  }
}

@media (max-width: 1126px) {
  .infoTransaccion {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));

  }
  .infoTransferencia {
      display: grid;
      align-content: center;
      grid-template-columns: repeat(1, minmax(0, 1fr));
  
  }
}

@media (max-width: 748px) {
  .buttonsGrid {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

    
  
}

.header {
  display: grid;
  justify-items: center;
  grid-template-areas: 
  "title title title title title title"
  "ubicacion ubicacion ubicacion ubicacion ubicacion ubicacion"
  "fechaInicio fechaInicio fechaInicio fechaFin fechaFin fechaFin "
  "producto producto categoria categoria company company";
}

@media (max-width: 748px) {
  .header {
    display: grid;
    justify-items: center;
    grid-template-areas:
    "title"
    "fechaInicio"
    "fechaFin"
    "ubicacion" 
    "producto" 
    "categoria" 
    "company"
  }
}

.title {
  grid-area: title;
}

.company{
  grid-area: company
}

.code {
  grid-area: code;
}

.dateInicio {
  grid-area: fechaInicio;
}

.dateFin {
  grid-area: fechaFin
}

.ubicacion {
  grid-area: ubicacion;
}

.producto {
  grid-area: producto;
}

.categoria {
  grid-area: categoria;
}
